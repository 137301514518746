import { useEffect, useState } from "react";
import { services } from "../utils/data";
import useEmblaCarousel from "embla-carousel-react";

import { DotButton, useDotButton } from "./EmblaDotNavigation";
import EmblaArrowNavigation from "./EmblaArrowNavigation";

type ServiceCardProps = {
  image: string;
  secondImage: string;
  title: string;
  paragraph: string;
};

const ServiceCard = ({
  image,
  title,
  paragraph,
  secondImage,
}: ServiceCardProps) => {
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle(!toggle);
    }, 6000);
    return () => clearInterval(interval);
  }, [toggle]);

  return (
    <section className=" w-full py-7f gap-7 flex flex-col items-center md:py-10f lg:flex-row lg:gap-20 lg:justify-between">
      <div
        data-aos="fade-up"
        className="select-none w-5/6 sm:w-4/6 md:w-3/6 lg:w-5/12 "
      >
        <img
          src={secondImage}
          className={`    ${toggle ? "fade_in w-full" : "fade_out"}`}
          alt=""
        />

        <img
          src={image}
          className={`${!toggle ? "fade_in w-full" : "fade_out"}`}
          alt=""
        />
      </div>
      <div
        data-aos="fade-up"
        className="w-full text-center sm:px-16 lg:text-left lg:w-3/6 lg:px-0"
      >
        <h3
          onClick={() => setToggle(!toggle)}
          className="font-bold text-primary-500 mb-5 text-lg md:text-xl lg:text-2xl"
        >
          {title}
        </h3>
        <p className="mb-4 text-black-400 md:text-lg">{paragraph}</p>
      </div>
    </section>
  );
};

const Services = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <section id="services" className="px-4 py-12 md:py-20">
      <h3 className="text-black-500 text-center text-xl font-bold mb-10 sm:text-2xl md:text-3xl lg:text-4xl ">
        Services
      </h3>
      <div className="embla mb-5" ref={emblaRef}>
        <div className="embla__container">
          {services.map((item) => (
            <div key={item?.id} className="embla__slide">
              <ServiceCard
                image={item.image}
                secondImage={item.secondImage}
                title={item.title}
                paragraph={item.paragraph}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex ? " embla__dot--selected" : ""
              )}
            />
          ))}
        </div>
        <EmblaArrowNavigation emblaApi={emblaApi} />
      </div>
    </section>
  );
};

export default Services;

import React from "react";
import { ReactComponent as Logo } from "../assets/SVGs/trava-logo-white.svg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../assets/SVGs/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/SVGs/instagram.svg";
import { ReactComponent as XIcon } from "../assets/SVGs/x.svg";

const Footer = () => {
  let navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <footer
      id="contact"
      className="px-5 py-16 bg-primary-500 text-black-200 md:px-14 md:py-20 lg:px-20"
    >
      <section className="mb-14 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:max-w-screen-2xl lg:mx-auto lg:grid-cols-4 lg:gap-5">
        <div className="w-full text-center">
          <span className="inline-block">
            <Logo
              onClick={() => {
                navigate("/");
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              className="w-36 h-40f mb-4 cursor-pointer md:mb-6"
            />
          </span>
        </div>

        <div className="w-full text-center">
          <p className="text-black-50 font-semibold mb-5 uppercase">Company</p>
          <ul>
            <li>
              <HashLink
                to="/#about"
                smooth
                className="text-black capitalize inline-block mb-2 transition hover:text-primary"
              >
                About Us
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#services"
                smooth
                className="text-black capitalize inline-block mb-2 transition hover:text-primary"
              >
                Services
              </HashLink>
            </li>

            <li>
              <HashLink
                to="/#faq"
                smooth
                className="text-black capitalize inline-block mb-2 transition hover:text-primary"
              >
                FAQ
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="w-full text-center">
          <p className="text-black-50 font-semibold mb-5 uppercase">Legal</p>
          <ul>
            <li>
              <HashLink
                to="/privacy-policy"
                smooth
                className="text-black capitalize inline-block mb-2 transition hover:text-primary"
              >
                Privacy Policy
              </HashLink>
            </li>
          </ul>
        </div>

        <div className="flex gap-3 justify-center mb-6">
          <Link
            to="https://facebook.com"
            target="_blank"
            className="bg-black-100 text-black-500 w-11 h-11 rounded-full flex justify-center items-center transition-colors duration-150 hover:bg-black-200"
            rel="noopener"
          >
            <FacebookIcon className="w-6" />
          </Link>
          <Link
            to="https://x.com"
            target="_blank"
            className="bg-black-100 text-black-500 w-11 h-11 rounded-full flex justify-center items-center transition-colors duration-150 hover:bg-black-200"
            rel="noopener"
          >
            <XIcon className="w-6" />
          </Link>
          <Link
            to="https://instagram.com"
            target="_blank"
            className="bg-black-100 text-black-500 w-11 h-11 rounded-full flex justify-center items-center transition-colors duration-150 hover:bg-black-200"
            rel="noopener"
          >
            <InstagramIcon className="w-6" />
          </Link>
        </div>
      </section>
      <p className="text-center text-sm">
        &copy; {currentYear} Trava, All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;

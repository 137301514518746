import React from "react";

const VideoIllustration = () => {
  return (
    <section className="px-4 w-full py-14 md:px-10 md:py-24">
      <video
        controls
        className="shadow-md mx-auto rounded-3xl w-full aspect-video  lg:w-5/6 "
      >
        <source
          src={
            "https://firebasestorage.googleapis.com/v0/b/store-66094.appspot.com/o/Trava%20app%20illustration.mp4?alt=media&token=6f8301b5-9467-45d4-be54-fe658904b095"
          }
          type="video/mp4"
        />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </section>
  );
};

export default VideoIllustration;

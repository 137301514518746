import TravaService12 from "../assets/images/trava-send-package.png";
import TravaService22 from "../assets/images/trava-pick-package.png";
import TravaService32 from "../assets/images/trava-reigister-hub.png";
import TravaService42 from "../assets/images/trava-security-reliability.png";
import TravaService1 from "../assets/images/trava-service-1.png";
import TravaService2 from "../assets/images/trava-service-2.png";
import TravaService3 from "../assets/images/trava-service-3.png";
import TravaService4 from "../assets/images/trava-service-4.png";

export const services = [
  {
    id: 1,
    title: "Send Packages At a Cheaper Rate",
    paragraph:
      "We know sending packages across distances has always been expensive but now, Trava let’s you half the cost and even get it delivered way faster with premium reliabilty.",
    image: TravaService1,
    secondImage: TravaService12,
  },
  {
    id: 2,
    title: "Pick Packages And Earn As You Travel",
    paragraph:
      "Getting an extra gig isn't the only way to make more money. Trava helps you combine travelling and earning at the same time whenever you deliver a package to your destination",
    image: TravaService2,
    secondImage: TravaService22,
  },
  {
    id: 3,
    title: "Earn Even More By Registering Your Hub",
    paragraph:
      "Registering your business as a pickup and drop-off hub, you get to not only earn with every pickup and drop-off but also bring traffic and exposure to your business. Register as a hub today and start earning",
    image: TravaService3,
    secondImage: TravaService32,
  },
  {
    id: 4,
    title: "Security And Reliablity With Every Delivery",
    paragraph:
      "No package mixup. Trava's unique package identifiers ensure both deliverers and recipients of packages have access to only what belongs to them.",
    image: TravaService4,
    secondImage: TravaService42,
  },
];

export const faqData = [
  {
    id: 1,
    question: "How do I track my package?",
    answer:
      "Once your package is on its way, you can track its progress in real-time through the Trava app. You’ll receive notifications at key points in the delivery process, including pickup, in transit, and delivery.",
  },
  {
    id: 2,
    question: "What is a hub in Trava?",
    answer:
      "A hub is a designated location where packages are stored temporarily before being picked up by a traveler or collected by the recipient. Hubs can be local businesses, secure public spaces, or even private individuals who register to store packages.",
  },
  {
    id: 3,
    question: "Who can register as a hub on Trava, and how do I sign up?",
    answer:
      "SMEs and larger businesses with secure locations can register as Trava hubs. Sign up through the Trava app or website, upload the required documents, and start earning by receiving packages once verified.",
  },

  {
    id: 4,
    question: "Is it safe to send packages through Trava?",
    answer:
      "Yes, Trava prioritizes the safety and security of all packages. We verify all users, travelers, and hubs through a rigorous process, and we offer real-time tracking and delivery confirmation to ensure your package is safe at all times.",
  },
  {
    id: 5,
    question: "What if something goes wrong during delivery?",
    answer:
      "In the rare event that something goes wrong, Trava offers customer support to assist with any issues. We also offer package insurance options for added peace of mind.",
  },
];
export const getStartedData = [
  {
    id: 1,
    title: "01. Download the app",
    description: " Get the TravaApp on Google Play Store or App Store for iOS.",
  },
  {
    id: 2,
    title: "02. Register within minutes",
    description:
      "Tell us a few details about you to help us create your TravaApp account.",
  },
  {
    id: 3,
    title: "03. Start earning",
    description:
      "You're all set to move. Carry out all your transactions within the app.",
  },
];

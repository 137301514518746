import HeroImage from "../assets/images/trava-hero.png";
import "aos/dist/aos.css";
// import { ReactComponent as DownloadIcon } from "../assets/SVGs/download.svg";
import { ReactComponent as ButtonArrowIcon } from "../assets/SVGs/button-arrow.svg";
import { HashLink } from "react-router-hash-link";

const Hero = () => {
  return (
    <section
      id="home"
      className="w-full px-4 pt-10 gap-5 flex flex-col md:items-center md:pt-24 lg:flex-row  lg:gap-0 lg:justify-between lg:items-center lg:pt-16"
    >
      <div
        data-aos="fade-up"
        className="w-full text-center lg:text-left lg:w-3/6f"
      >
        <h1 className="font-black mb-4 text-3xl text-black-500 sm:text-4xl md:text-5xl">
          A new way to <span className="text-secondary-500">deliver.</span>{" "}
          <br /> A new way to <span className="text-secondary-500">earn.</span>
        </h1>
        <h2 className="text-black-400 max-w-sm mx-auto mb-14 sm:text-lg  md:max-w-lg lg:mx-0">
          Connecting travelers with parcels and local hubs for convenient
          drop-off and pickup
        </h2>

        <div
          data-aos="fade-left"
          data-aos-delay="250"
          className="flex gap-2 justify-center md:gap-4 lg:justify-start"
        >
          {/* <HashLink to="/#download">
            <button className="border-none outline-none bg-primary-500  text-black-50 font-medium w-52 h-14 px-5 py-2 rounded-xl flex  items-center justify-center gap-3 transition duration-150 hover:bg-primary-400 sm:w-52">
              <span>Download App</span>
              <span className="inline-block w-6">
                <DownloadIcon />
              </span>
            </button>
          </HashLink> */}
          <HashLink to="/#get-started">
            <button className="border-none outline-none bg-primary-500  text-black-50 font-medium w-52 h-14 px-5 py-2 rounded-xl flex  items-center justify-center gap-3 transition duration-150 hover:bg-primary-400 sm:w-52">
              <span>Get Started</span>
              <span className="inline-block w-6">
                <ButtonArrowIcon />
              </span>
            </button>
          </HashLink>
        </div>
      </div>

      <img
        data-aos="fade-left"
        src={HeroImage}
        className="w-full object-cover md:w-4/6 lg:w-3/6"
        alt=""
      />
    </section>
  );
};

export default Hero;

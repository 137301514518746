import { ReactComponent as ArrowRightIcon } from "../assets/SVGs/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/SVGs/arrow-left.svg";
import { useCallback, useEffect, useState } from "react";
import { EmblaCarouselType } from "embla-carousel";

type EmblaArrowNavigationProps = {
  emblaApi: EmblaCarouselType | undefined;
};
const EmblaArrowNavigation = ({ emblaApi }: EmblaArrowNavigationProps) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  // handle scroll to previous slide
  const handlePrevScroll = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  // handle scroll to next slide
  const handleNextScroll = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);
  return (
    <div className="flex justify-end items-center gap-4 ">
      <button
        disabled={prevBtnDisabled}
        onClick={handlePrevScroll}
        className="embla__prev outline-none h-10 w-10 rounded-full inline-flex justify-center items-center border border-black-500 text-black-500 transition-colors duration-150 disabled:border-black-200 disabled:text-black-200 md:h-12 md:w-12"
      >
        <ArrowLeftIcon />
      </button>
      <button
        disabled={nextBtnDisabled}
        onClick={handleNextScroll}
        className="embla__next outline-none h-10 w-10 rounded-full inline-flex justify-center items-center border border-black-500 text-black-500 transition-colors duration-150 disabled:border-black-200 disabled:text-black-200 md:h-12 md:w-12"
      >
        <ArrowRightIcon />
      </button>
    </div>
  );
};

export default EmblaArrowNavigation;

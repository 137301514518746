import { ReactComponent as PlusIcon } from "../assets/SVGs/plus.svg";
import { ReactComponent as MinusIcon } from "../assets/SVGs/minus.svg";
import { useState } from "react";
import { faqData } from "../utils/data";

const FAQ = () => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  const handleItemClick = (item: number) => {
    if (item === selectedItem) {
      setSelectedItem(null);
      return;
    }

    setSelectedItem(item);
  };

  return (
    <section
      id="faq"
      className="px-4 py-10 mb-5 sm:px-10 md:px-20 md:py-24 lg:px-32 "
    >
      <h3 className="text-black-500 text-xl font-bold mb-4 sm:text-2xl md:text-3xl lg:text-4xl ">
        Frequently Asked Questions (FAQs)
      </h3>
      <p className="text-black-500 text-sm mb-14  sm:text-base md:mb-20 lg:text-lg">
        Find Answers to Common Queries about Trava
      </p>
      <div>
        {faqData.map((item) => (
          <div
            key={item.id}
            data-aos="fade-in"
            className="mb-4 w-full px-4 bg-black-100 rounded-2xl md:px-6 lg:px-10"
          >
            <label className="w-full">
              <input
                type="checkbox"
                name="faq"
                checked={item.id === selectedItem}
                onChange={() => handleItemClick(item.id)}
                className="peer sr-only"
              />{" "}
              <p className="py-8 w-full flex justify-between items-center gap-6 font-bold cursor-pointer text-black-500 text-sm sm:text-base md:text-lg">
                <span>{item.question}</span>
                <span className="w-6">
                  {item.id === selectedItem ? <MinusIcon /> : <PlusIcon />}
                </span>
              </p>
              <p
                onClick={(e) => e.preventDefault()}
                className=" text-black-400 text-sm max-h-0 transition-all duration-200 overflow-hidden peer-checked:max-h-40  peer-checked:pb-8 sm:text-base"
              >
                {item.answer}
              </p>
            </label>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;

import { useEffect } from "react";
import AppWrapper from "../layouts/AppWrapper";
import Hero from "../components/Hero";
import GetStarted from "../components/GetStarted";
import About from "../components/About";
import VideoIllustration from "../components/VideoIllustration";
import FAQ from "../components/FAQ";
import AOS from "aos";
import "aos/dist/aos.css";
import Service from "../components/Service";

const Home = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <AppWrapper>
      <Hero />
      <About />
      <Service />
      <VideoIllustration />
      <GetStarted />
      <FAQ />
    </AppWrapper>
  );
};

export default Home;

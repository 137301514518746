import "aos/dist/aos.css";
import AppStoreButton from "./AppStoreButton";
import GooglePlayButton from "./GooglePlayButton";
import { getStartedData } from "../utils/data";

type GetStartedCardProps = {
  title: string;
  description: string;
};
const GetStartedCard = ({ title, description }: GetStartedCardProps) => {
  return (
    <div
      data-aos="fade-up"
      className="bg-black-50 border-2 border-white shadow-sm py-8 px-5  shadow-smf rounded-xl md:px-7 md:w-[410px]"
    >
      <p className="font-semibold text-primary-500 text-xl mb-5">{title}</p>
      <p className="text-black-400">{description}</p>
    </div>
  );
};

const GetStarted = () => {
  return (
    <section
      id="get-started"
      className="px-4 py-20 bg-black-100 md:rounded-2xl md:px-10"
    >
      <h3 className="text-black-500 text-center text-xl font-bold mb-10 sm:text-2xl md:text-3xl lg:text-4xl ">
        Get Started
      </h3>

      <div className="flex flex-col gap-5 md:flex-row  justify-center md:flex-wrap md:items-center md:gap-6 lg:gap-8">
        {getStartedData?.map((item) => (
          <GetStartedCard
            key={item?.id}
            title={item?.title}
            description={item?.description}
          />
        ))}
      </div>
      <div
        data-aos="fade-up"
        className="mx-auto mt-16 flex gap-2 items-center justify-center sm:gap-4"
      >
        <AppStoreButton />
        <GooglePlayButton />
      </div>
    </section>
  );
};

export default GetStarted;

import React from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";

type AppWrapperProp = {
  children: React.ReactNode;
};
const AppWrapper = ({ children }: AppWrapperProp) => {
  return (
    <>
      <Navbar />
      <main className="lg:max-w-screen-2xl lg:mx-auto">
        <main className="mt-20  md:mt-16 md:px-14 lg:px-20 lg:mt-0">
          {children}
        </main>
      </main>
      <Footer />
    </>
  );
};

export default AppWrapper;

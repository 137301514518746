import { ReactComponent as AppStoreIcon } from "../assets/SVGs/apple-logo.svg";

const AppStoreButton = () => {
  return (
    <button className="border-none outline-none bg-primary-500 text-black-50 w-44 py-2 px-5 rounded-xl flex gap-3 items-center justify-betweenf transition duration-150 sm:w-52 sm:px-8 sm:gap-4">
      <span className="w-5">
        <AppStoreIcon />
      </span>
      <p className="text-left">
        <span className="text-xs block md:text-sm">Download on the</span>
        <span className="font-bold text-sm sm:text-base">App Store</span>
      </p>
    </button>
  );
};

export default AppStoreButton;

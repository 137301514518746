import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/SVGs/trava-logo-white.svg";
import { ReactComponent as HamburgerIcon } from "../assets/SVGs/hamburger.svg";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  let navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // handle navbar appearance/disappearance
  const handleScroll = () => {
    if (window.scrollY > lastScrollY && !isMobileMenuOpen) {
      setIsNavbarVisible(false);
    } else {
      setIsNavbarVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);
  // disable scroll on the page when navbar is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMobileMenuOpen]);

  // close mobile navbar when window's width is greater than tailwind's default large screen size (1024px)
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 1024) setIsMobileMenuOpen(false);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      {isMobileMenuOpen && (
        <div
          onClick={() => setIsMobileMenuOpen(false)}
          className="z-40 fixed  w-screen h-screen top-0 left-0 bg-white/20 backdrop-blur-sm lg:hidden"
        ></div>
      )}
      <nav
        className={`fixed z-50 top-0 left-0 shadow-lg w-full bg-primary-500 backdrop-blur-sm flex justify-between items-center  p-4 transition duration-700 md:px-14 md:py-7 lg:px-24  ${
          isNavbarVisible ? "translate-y-0" : "-translate-y-[calc(100%+20px)]"
        }`}
      >
        <Logo
          onClick={() => {
            navigate("/");
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="w-20 h-10f cursor-pointer md:w-24"
        />
        <ul
          className={`bg-white shadow-lg w-80 rounded-lg fixed z-50 top-4 right-4 flex flex-col gap-10 px-10 py-12 md:px-16 md:top-10 md:right-10 md:w-96 lg:shadow-none lg:bg-transparent lg:w-auto lg:text-black-50 lg:backdrop-blur-none  lg:flex-row lg:h-auto lg:p-0 lg:flex lg:static lg:gap-20 ${
            isMobileMenuOpen ? "block" : "hidden"
          }`}
        >
          <li>
            <svg
              onClick={() => setIsMobileMenuOpen(false)}
              className="absolute right-7 top-7 cursor-pointer lg:hidden"
              width="14"
              height="14"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.9999 25.7598L2.23438 2"
                stroke="#333333"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.7656 2.24023L2 26"
                stroke="#333333"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </li>

          <li>
            <HashLink
              to="/#about"
              smooth
              className="select-none capitalize cursor-pointer text-black-500 transition relative hover:text-black-400 lg:text-black-50 lg:text-lg lg:hover:text-primary-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              About Us
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/#services"
              smooth
              className="select-none capitalize cursor-pointer text-black-500 transition relative hover:text-black-400 lg:text-black-50 lg:text-lg lg:hover:text-primary-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Services
            </HashLink>
          </li>

          <li>
            <HashLink
              to="/#faq"
              smooth
              className="select-none capitalize cursor-pointer text-black-500 transition relative hover:text-black-400 lg:text-black-50 lg:text-lg lg:hover:text-primary-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              FAQ
            </HashLink>
          </li>
        </ul>

        {/* hamburger menu */}
        {!isMobileMenuOpen && (
          <span
            onClick={() => setIsMobileMenuOpen(true)}
            className="w-5 cursor-pointer text-white transition-colors duration-150 hover:text-primary-500 lg:hidden "
          >
            <HamburgerIcon />
          </span>
        )}
      </nav>
    </>
  );
};

export default Navbar;

import "aos/dist/aos.css";
import AboutImage from "../assets/images/about-image.jpg";

const About = () => {
  return (
    <section
      id="about"
      className="px-4 grid grid-cols-1 gap-16 py-12 bg-black-100 md:rounded-3xl md:p-20  lg:grid-cols-2 lg:items-center lg:gap-28 lg:p-24"
    >
      <div data-aos="fade-right" className="relative">
        <div className="absolute z-[-1] top-5 -right-5 w-full h-full bg-black-200 border-2 border-black-50 rounded-3xl hidden md:block"></div>
        <img
          src={AboutImage}
          alt=""
          className="object-cover shadow-md border-2 border-black-50 rounded-3xl h-[500px] w-full"
        />
      </div>
      <div data-aos="fade-left" className="text-black-300 w-full">
        <h3 className="text-black-500 text-xl font-bold mb-10 sm:text-2xl md:text-3xl lg:text-4xl ">
          About Us
        </h3>
        <p className="mb-7 md:text-lg">
          Trava empowers delivery by utilizing human capacity to not only half
          the cost of delivery, but also make for fast, secured and more
          reliable delivery in a rewarding way. With Trava you can earn as a
          traveler when you deliver package at your point of way, also as a
          package holder when you register your store as a hub for package
          drop-off and pickup. Download the app today and empower you and your
          business.
        </p>
      </div>
    </section>
  );
};

export default About;

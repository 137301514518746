import React from "react";
import AppWrapper from "../layouts/AppWrapper";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  let navigate = useNavigate();
  return (
    <AppWrapper>
      <section className="py-32">
        <h1 className="text-6xl tracking-wide text-center text-primary font-bold md:text-7xl">
          404
        </h1>
        <h2 className="capitalize text-xl text-center mt-3 mb-6 font-medium">
          Page. not. found.
        </h2>
        <div className="text-center">
          <button
            onClick={() => navigate("/")}
            className="h-12 w-56 rounded-md bg-primary text-white"
          >
            Go Home
          </button>
        </div>
      </section>
    </AppWrapper>
  );
};

export default ErrorPage;
